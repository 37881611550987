import React from "react";
import '@/styles/index.css'
import 'swiper/swiper.scss';
import 'swiper/components/a11y/a11y.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import {Provider} from "@/lib/utils/context";
import {useRouter} from "next/router";
import * as Sentry from "@sentry/react";
import {Integrations} from "@sentry/tracing";

const {NEXT_PUBLIC_SENTRY_DSN: dsn} = process.env;
if (dsn) {
  Sentry.init({
    dsn,
    integrations: [
      new Integrations.BrowserTracing(),
    ],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  })
}

export default function MyApp({Component, pageProps}) {
  const router = useRouter()
  return router.isFallback || (
      <Provider pageProps={pageProps}>
        <Component {...pageProps} />
      </Provider>
  );
}
